
function toogleModal(modal, btn ) {
    if(!btn){
        modal.show();
        $('body').addClass('hidden');
    } else{
        btn.click(function () {
            button = $(this);
            modal.show();
            $('body').addClass('hidden');
        });
    }

    $('.modal__close').click(function () {
        closeModal(modal)
        return false;
    });
    $('.modal__btn-close').click(function () {
        closeModal(modal)
        return false;
    });
    $(document).keydown(function (e) {
        if (e.keyCode === 27) {
            e.stopPropagation();
            closeModal(modal)
        }
    });
    modal.click(function (e) {
        if ($(e.target).closest('.modal__content').length == 0) {
            closeModal(modal)
        }
    });
}


function closeModal(modal) {
    modal.hide();
    $('body').removeClass('hidden');
    resetModal();
    $('.modal__video-content > *').remove();
}

function chooseNumber(){
    $(document).on('click','.read__button', function (){
        $('.read__button').removeClass('active')
        $(this).addClass('active')
        let count = $(this).find('span').text()

        let obj = { action: 'show-date', count  };

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            data: obj,
            method: 'POST',
            success: function (res) {
                console.log('success ajax');
                $('.main').replaceWith(res)
            },
            error: function (error) {
                console.log('error ajax');
            },
        });

    })
}
function resetModal(){

}

function tab() {
    $(".tab__header-item").click(function () {
        $(".tab__header-item").removeClass("active").eq($(this).index()).addClass("active");
        $(".tab__content-item").hide().eq($(this).index()).fadeIn();
    }).eq(0).addClass("active");
}

function playVideo(){
    $(document).on('click', '.video__poster', function () {
        let video = '<iframe allowFullScreen allow=" fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameBorder="0" src="' + $(this).attr('data-video') + '" title="' + $(this).attr('data-title') + '"></iframe>';
        $('.modal__video-content').append(video);
        toogleModal($('.modal__video'))
    });
};

function showMore(){
    let content = $('.today .content');
    let readMoreButton = $('.today__more');

    if (content[0].scrollHeight > 118) {
        readMoreButton.show();
    }

    readMoreButton.on('click', function() {
        // content.toggleClass('expanded');
        if (content.hasClass('expanded')) {
            content.removeClass('expanded');
            readMoreButton.text('Read More');
        } else {
            content.addClass('expanded');
            readMoreButton.text('Show Less');
        }
    });
}


$(document).ready(function(){
    chooseNumber()
    tab()
    playVideo()
    showMore()
});

$(window).load(function(){

});

$(window).resize(function(){

});